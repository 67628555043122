<!--
 * @Description: 设备运维管理 设备管理 卡扣立柱 bayonetPillar
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2022-02-16 14:18:15
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainbody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm"
               class="searchArea">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="立柱编号">
              <el-input v-model="searchForm.deviceId"
                        placeholder="请输入立柱编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="立柱名称">
              <el-input v-model="searchForm.deviceName"
                        placeholder="请输入立柱名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="立柱地址">
              <el-input v-model="searchForm.address"
                        placeholder="请输入立柱地址"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="厂商">
              <el-input v-model="searchForm.vendor"
                        placeholder="请输入厂商"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topSecondLine">
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="设备状态">
              <el-select v-model="searchForm.status"
                         placeholder="全部">
                <el-option v-for="item in statusList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="handleClickSearch">查 询</el-button>
          <el-button type="info"
                     style="margin-left:17px"
                     @click="handleClickReset">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>卡口立柱管理</span>
        <el-row class="topButton tableTitleButton">
          <el-button type="success"
                     @click="handleClickAdd">新 增</el-button>
          <el-button type="info"
                     style="margin-left:17px"
                     @click="importDialog = true">导 入</el-button>
          <el-button type="info"
                     style="margin-left:17px">
            <a :href="exportFile"
               download=""
               style="color:white">导 出</a>
          </el-button>
          <el-button type="danger"
                     style="margin-left:17px"
                     @click="massDeletion">批量删除</el-button>
        </el-row>
      </el-row>
      <el-row class="tableContent">
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :config="tableConfig"
                  :paginationConfig='paginationConfig'
                  :tableFun="tableFun"
                  @getList="getList">
          <template slot="isFirst">
            <el-table-column type="selection"></el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-row>
                  <el-col :span="8">
                    <el-button @click="handleClickDetail(scope.row)"
                               type="text"
                               size="medium">详情</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button type="text"
                               size="medium"
                               @click="handleClickEdit(scope.row)">修改</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button type="text"
                               size="medium"
                               @click="handleClickDelete(scope.row)">删除</el-button>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 新增 -->
    <el-dialog title="新增"
               :visible.sync="addDialogVisible"
               append-to-body
               @close="queryTableList">
      <el-form label-width="150px"
               :model="addForm"
               :rules="rules"
               ref="addForm"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="立柱编号"
                          prop="deviceId">
              <el-input v-model="addForm.deviceId"
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="立柱名称"
                          prop="deviceName">
              <el-input v-model="addForm.deviceName"
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="归属停车场"
                          prop="parkId">
              <el-select v-model="addForm.parkId"
                         style="width:230px"
                         placeholder=""
                         @change="queryPassagewayList">
                <el-option v-for="item in parkList"
                           :label="item.name"
                           :value="item.code"
                           :key="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="停车场出入口"
                          prop="passagewayId">
              <el-select v-model="addForm.passagewayId"
                         style="width:230px"
                         placeholder="">
                <el-option v-for="item in passagewayList"
                           :label="item.name"
                           :value="item.code"
                           :key="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="设备型号"
                          prop="version">
              <el-input v-model="addForm.version"
                        class="dt-form-width"
                        placeholder=""
                        style="width:230px">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属厂商"
                          prop="vendor">
              <el-input v-model="addForm.vendor"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="联系人"
                          prop="contact">
              <el-input v-model="addForm.contact"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话"
                          prop="contactPhoneNumber">
              <el-input v-model="addForm.contactPhoneNumber"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="经度"
                          prop="longitude">
              <el-input v-model="addForm.longitude"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="纬度"
                          prop="latitude">
              <el-input v-model="addForm.latitude"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="设备地址"
                          prop="address">
              <el-input v-model="addForm.address"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注">
              <el-input v-model="addForm.remark"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="设备最后心跳时间"
                          prop="a">
              <el-input v-model="addForm.a"
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     @click="handleClickAddConfirm('addForm')">确 定</el-button>
          <el-button @click="addDialogVisible = false"
                     type="info">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog title="修改"
               :visible.sync="editDialogVisible"
               append-to-body
               @close="queryTableList">
      <el-form label-width="150px"
               :model="editForm"
               :rules="rules"
               ref="addForm"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="立柱编号"
                          prop="deviceId">
              <el-input v-model="editForm.deviceId"
                        readonly=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="立柱名称"
                          prop="deviceName">
              <el-input v-model="editForm.deviceName"
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="归属停车场"
                          prop="parkId">
              <el-select v-model="editForm.parkId"
                         style="width:230px"
                         placeholder=""
                         @change="queryPassagewayList">
                <el-option v-for="item in parkList"
                           :label="item.name"
                           :value="item.code"
                           :key="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="停车场出入口"
                          prop="passagewayId">
              <el-select v-model="editForm.passagewayId"
                         style="width:230px"
                         placeholder="">
                <el-option v-for="item in passagewayList"
                           :label="item.name"
                           :value="item.code"
                           :key="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="设备型号"
                          prop="version">
              <el-input v-model="editForm.version"
                        class="dt-form-width"
                        placeholder=""
                        style="width:230px">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属厂商"
                          prop="vendor">
              <el-input v-model="editForm.vendor"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="联系人"
                          prop="contact">
              <el-input v-model="editForm.contact"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话"
                          prop="contactPhoneNumber">
              <el-input v-model="editForm.contactPhoneNumber"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="经度"
                          prop="longitude">
              <el-input v-model="editForm.longitude"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="纬度"
                          prop="latitude">
              <el-input v-model="editForm.latitude"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="设备地址"
                          prop="address">
              <el-input v-model="editForm.address"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注">
              <el-input v-model="editForm.remark"
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="设备最后心跳时间"
                          prop="a">
              <el-input v-model="editForm.a"
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     @click="handleClickEditConfirm()"
                     style="margin-left:17px;
                     border-radius:4px;
                     height:36px;">确 定</el-button>
          <el-button @click="editDialogVisible = false"
                     type="info">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 查看 -->
    <el-dialog title="详情"
               :visible.sync="detailDialogVisible"
               append-to-body>
      <el-form label-width="150px"
               :model="detailForm"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="立柱编号"
                          prop="deviceId">
              <el-input v-model="detailForm.deviceId"
                        readonly
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="立柱名称"
                          prop="deviceName">
              <el-input v-model="detailForm.deviceName"
                        readonly
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="归属停车场"
                          prop="parkId">
              <el-select v-model="detailForm.parkId"
                         disabled
                         style="width:230px"
                         placeholder="">
                <el-option v-for="item in parkList"
                           :label="item.name"
                           :value="item.code"
                           :key="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="停车场出入口"
                          prop="passagewayId">
              <el-select v-model="detailForm.passagewayName"
                         disabled
                         style="width:230px"
                         placeholder="">
                <el-option v-for="item in passagewayList"
                           :label="item.name"
                           :value="item.code"
                           :key="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="设备型号"
                          prop="version">
              <el-input v-model="detailForm.version"
                        readonly
                        class="dt-form-width"
                        placeholder="">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属厂商"
                          prop="vendor">
              <el-input v-model="detailForm.vendor"
                        readonly
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="联系人"
                          prop="contact">
              <el-input v-model="detailForm.contact"
                        readonly
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话"
                          prop="contactPhoneNumber">
              <el-input v-model="detailForm.contactPhoneNumber"
                        readonly
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="经度"
                          prop="longitude">
              <el-input v-model="detailForm.longitude"
                        readonly
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="纬度"
                          prop="latitude">
              <el-input v-model="detailForm.latitude"
                        readonly
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="设备地址"
                          prop="address">
              <el-input v-model="detailForm.address"
                        readonly
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注">
              <el-input v-model="detailForm.remark"
                        readonly
                        placeholder=""
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row justify="space-around">

          <el-col :span="12">
            <el-form-item label="设备最后心跳时间"
                          prop="a">
              <el-input v-model="detailForm.a"
                        readonly
                        class="dt-form-width">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button @click="detailDialogVisible = false"
                     type="info">返 回</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 导入 -->
    <el-dialog title="导入数据"
               :visible.sync="importDialog"
               :close-on-click-modal="false"
               append-to-body>
      <el-upload ref="upload"
                 :auto-upload="false"
                 :file-list="fileList"
                 :http-request="myUpload"
                 :limit="1"
                 :on-change="addFile"
                 :on-exceed="handleExceed"
                 :show-file-list="true"
                 accept=".xls, .xlsx"
                 action=""
                 style="text-align: center;">
        <el-row style="display:flex">
          <el-button size="small"
                     style="margin-left: 15px"
                     type="primary">
            <a :href="templateDl"
               class="download"
               download=""
               style="color: #ffffff;text-decoration:none">模板下载</a>
          </el-button>
          <el-button size="small"
                     type="primary">
            选择文件
          </el-button>
        </el-row>
        <div slot="tip"
             class="el-upload__tip"
             style="font-size:10px;color:#ff0000;margin-top:30px;">
          请先下载模板！
        </div>
      </el-upload>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     class="popBtn"
                     @click="confimImportBatch">导 入</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  //监听属性 类似于data概念
  computed: {
    exportFile: function () {
      return this.$downloadBaseUrl + "viewScreen/download";
    }
  },
  data () {
    return {
      tableFun: { 'selection-change': this.handleChange, },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      tableColumnList: [
        {
          prop: 'deviceId',
          label: '立柱编号',
        },
        {
          prop: 'deviceName',
          label: '立柱名称',
        },
        {
          prop: 'parkName',
          label: '归属停车场',
        },
        {
          prop: 'passagewayName',
          label: '停车场出入口',
        },
        {
          prop: 'address',
          label: '立柱地址',
        },
        {
          prop: 'vendor',
          label: '厂商',
        },
        {
          prop: 'statusDesc',
          label: '设备状态',
        }
      ],
      tableList: {
        list: [],
      },
      searchForm: {},  // 搜索表单
      pageNum: 1,
      pageSize: 15, // 初始化分页

      parkList: [],// 停车场名称列表
      passagewayList: [], // 出入口列表
      statusList: [], // 设备状态

      addDialogVisible: false, // 新增弹窗显示
      addForm: {},//新增表单

      editDialogVisible: false,//修改弹窗显示
      editForm: {},//修改表单

      detailDialogVisible: false, // 查看详情弹窗显示
      detailForm: {},//查看表单

      massDeleteList: [], // 批量删除绑定

      importDialog: false, // 导入弹窗
      fileList: [],
      templateDl: "",

      rules: {
        deviceId: [{ required: true, message: '请输入', trigger: 'blur' }],
        deviceName: [{ required: true, message: '请输入', trigger: 'blur' }],
        version: [{ required: true, message: '请输入', trigger: 'blur' }],
        vendor: [{ required: true, message: '请输入', trigger: 'blur' }],
        contact: [{ required: true, message: '请输入', trigger: 'blur' }],
        contactPhoneNumber: [{ required: true, message: '请输入', trigger: 'blur' }],
        address: [{ required: true, message: '请输入', trigger: 'blur' }],
        longitude: [{ required: true, message: '请输入', trigger: 'blur' }],
        latitude: [{ required: true, message: '请输入', trigger: 'blur' }],
        parkId: [{ required: true, message: '请选择', trigger: 'change' }],
        passagewayId: [{ required: true, message: '请选择', trigger: 'change' }],
      }
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.templateDl = this.$downloadBaseUrl + "viewScreen" + "/downloadTemplate";
    this.queryParkList()
    this.queryStatusList()
    this.queryTableList()
  },
  //方法集合
  methods: {
    // 上传文件
    addFile (file) {
      if (!(file.name.endsWith("xls") || file.name.endsWith("xlsx"))) {
        this.fileList = [];
        this.$message.warning(`文件格式有误,请选择正确的Excel文件`);
      }
    },
    // 限制文件
    handleExceed () {
      this.$message.warning(`对不起,一次仅限上传一个文件！`);
    },
    // 上传文件  
    myUpload (content) {
      let _self = this;
      var FileController = "";
      FileController = this.$downloadBaseUrl + "viewScreen/upload";
      var form = new FormData();
      form.append("file", content.file);
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = onloadFun;
      xhr.open("POST", FileController, true);
      xhr.send(form);
      function onloadFun () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          var resText = JSON.parse(xhr.responseText);
          if (resText.resultCode === "2000") {
            _self.fileList = [];
            _self.$message({ message: "导入成功", type: "success" });
            _self.importDialog = false;
            _self.queryTableList();
          } else {
            _self.$message.error({ message: "对不起！文件上传失败", type: "error" });
          }
        }
      }
    },
    // 确认导入按钮
    confimImportBatch () {
      this.$refs.upload.submit();
      this.importDialog = false;
    },
    // 默认表格查询
    queryTableList () {
      this.searchForm["pageNum"] = this.pageNum
      this.searchForm["pageSize"] = this.pageSize
      this.$bayonetPillar.queryViewScreenList(this.searchForm).then(res => {
        this.tableList.list = res.resultEntity.list
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    // 查询按钮
    handleClickSearch () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.queryTableList()
    },
    // 重置按钮
    handleClickReset () {
      this.searchForm = {}
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.queryTableList()
    },
    // 新增按钮
    handleClickAdd () {
      this.addDialogVisible = true
      this.addForm = {}
    },
    // 新增确定按钮
    handleClickAddConfirm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$bayonetPillar.addViewScreen(this.addForm).then(() => {
            this.$message({ message: '新增成功', type: 'success' });
            this.addDialogVisible = false
            this.queryTableList()
          }).catch(() => {
            this.$message.error('卡口立柱编号已存在')
          })
        }
      })
    },
    //修改按钮
    handleClickEdit (row) {
      this.editFormList = {}
      this.queryPassagewayList(row.parkId)
      this.editForm = row
      this.editDialogVisible = true
    },
    // 修改确认按钮
    handleClickEditConfirm () {
      this.$bayonetPillar.updateViewScreen(this.editForm).then(() => {
        this.$message({
          message: '修改成功', type: 'success'
        });
        this.queryTableList()
        this.editDialogVisible = false
      })
    },
    //删除
    handleClickDelete (row) {
      this.$confirm('是否删除该设备?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$bayonetPillar.deleteViewScreen([{ deviceId: row.deviceId }]).then(() => {
          this.$message({
            message: '删除成功', type: 'success'
          });
          this.queryTableList()
        })
      })
    },
    //表格多选按钮
    handleChange (val) {
      let arr = []
      val.forEach((item) => {
        arr.push({ deviceId: item.deviceId, parkId: item.parkId })
      })
      this.massDeleteList = arr
    },
    //批量删除
    massDeletion () {
      if (this.massDeleteList.length === 0) {
        this.$message({ showClose: true, message: '至少勾选一条记录', type: 'error' });
      } else {
        this.$confirm('确定批量删除设备?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let info = this.massDeleteList
          this.$bayonetPillar.deleteViewScreen(info).then((response) => {
            if (response.resultCode == 2000) {
              this.$message({ message: '删除成功', type: 'success' });
              this.queryTableList()
            }
          })
        })
      }
    },

    // 点击查看详情
    handleClickDetail (row) {
      this.detailForm = row
      this.detailDialogVisible = true
    },
    // 获取停车场编号
    queryParkList () {
      let info = {
        columnName: ['park_id', 'park_name'],
        tableName: 'tb_park',
      }
      this.$queryDict.queryDictWithAuth(info).then(res => {
        this.parkList = res.resultEntity
      })
    },
    // 获取出入口编号
    queryPassagewayList (val) {
      this.editForm.passagewayId = ""
      this.passagewayList = []
      let info = {
        columnName: ["passageway_id", "passageway_name"],
        tableName: "tb_passageway",
        whereStr: [
          {
            colName: 'park_id',
            value: val,
          },
        ],
      }
      this.$queryDict.queryDict(info).then(res => {
        this.passagewayList = res.resultEntity
      })
    },
    // 设备状态
    queryStatusList () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: 'BE5701825A9D444F959E53CC17F664A8G',
          },
        ],
      }
      this.$queryDict.queryDict(info).then(res => {
        this.statusList = res.resultEntity
      })
    },
  },
  //监控data中的数据变化
  detail: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 185px;
.mainbody {
  height: 100%;
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 111px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .topButton {
        display: flex;
        padding-left: 30px;
      }
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
.dt-form-width {
  width: 230px;
}
.title {
  font-size: 16px;
  color: #70aee8;
  line-height: 18px;
}
.content {
  font-size: 14px;
  color: #bae8ff;
  line-height: 18px;
}
.lineOne {
  margin-top: 25px;
}
.imageBox {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
</style>
